import React from 'react';
import Card from './card';

//const CardList = (props) =>{
const CardList = ({robots}) =>{
    const cardArray = robots.map((user, i)=>{
        //return <Card key={robots[i].id} words={robots[i]}/>;
        
        return <Card key={robots[i].id} id={robots[i].id} category={robots[i].category} img={robots[i].imageUrl} hanzi={robots[i].words}/>;
    });

    return(
        <div>
            {cardArray}
        </div>
    );
}

export default CardList;
