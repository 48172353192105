import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => (
    <nav>
        <ul>
            <Link to="/"><img alt="ChineseIn10" src="/images/icon-300-64.png"/></Link>
        </ul>
        {/*<ul>
            <li>
                <Link to="/">ChineseIn10.com</Link>
                <Link to="/">Home2</Link>
                <Link to="/about">About</Link>
            </li>
        </ul>*/}
    </nav>
);

//<Link to="/article-list">Login</Link>

export default NavBar;
