import React from 'react';
import { Link } from 'react-router-dom';

//<img alt='card' src='https://robohash.org/bob?200x200' />
const Card = (props) =>{
    const img = `images/${props.img}`;

    return(
        <div className="bg-lightest-blue dib br3 pa3 ma2 grow bw2 shadow-5">
           <Link to={{
                pathname: `/words/${props.id}`}}>
                <img alt='card' src={img}/>
            </Link>
        </div>
    );
}

export default Card;
