import React from 'react';

const AboutPage = (props) =>{

    return(
      <div className="about">
          <p>
            ChineseIn10 focuses on learning Mandarin Chinese vocabulary using the 'power of 10 rule'. Each word list will give you 10 related to words to focus on. 10 words is an optimal number to learn at one time, any less will mean slow progress and too many words will be difficult to remember. To learn effectively you need to have short term defined goals with a measure of your progress. This leads to a sense of achievement and encourages further learning.
            <br /><br />
            The word lists mainly focus on learning nouns and adjectives (names & descriptive words) since most Chinese courses will concentrate on grammar type words. It's often the case that Chinese grammar can be learnt fairly quickly but fluency only comes through learning enough vocabulary to speak and understand the language. If you have a large vocabulary and poor grammar you can still understand written Chinese, make yourself understood when speaking and be able to listen to conversation.
            <br /><br />
            Think about ordering beer in a restaurant, taking a taxi to the airport or buying milk in a shop. You can accomplish all these tasks by just using a few noun words. Reading a restaurant menu requires a good knowledge of food vocabulary. With good grammar skills but poor vocabulary you'll struggle to use Chinese effectively.
          </p>
      </div>
    );
}

export default AboutPage;
