import React from 'react';

const searchBox = ({searchChange}) =>{
    return(
        <div className='pa2'>
            <input 
                className='pa2 ba b--black bg-lightest-grey'
                type='search'
                placeholder='search category' 
                onChange={searchChange}
            />
        </div>
    );
}

export default searchBox;
