import React from 'react';
import {WORD_LISTS} from '../words.js';

// const ListItems = ({words}) => {
//     //console.log(words);
//     //return <p></p>

//     words.map((d) => {
//         console.log(d.english_name);
//         return (<p>{d.english_name}</p>);
//     // return(
//     //     <tr key={d.id}>
//     //         <td>{d.english_name}</td>
//     //         <td>{d.pinyin}</td>
//     //         <td>{d.chinese_name}</td>
//     //     </tr>
// //)
// })};

function WordList(props) {
  const words = props.words;
  //console.log(words);
  const listItems = words.map((d) =>
    //<li>{w.english_name}</li>
    <tr key={d.id}>
      <td>{d.english_name}</td>
      <td>{d.pinyin}</td>
      <td>{d.chinese_name}</td>
    </tr>
  );
  return (
    <table id="wordtable">
      <tbody>
        <tr>
          <th className="col-English">English</th>
          <th className="col-Pinyin">Pinyin</th>
          <th className="col-Chinese">Chinese</th>
        </tr>
      {listItems}
      </tbody>
    </table>
    //<ul>{listItems}</ul>
  );
}

//const WordPage = ({match}) =>{
const WordPage = (props) =>{
    //const name = props.match.params.name;
    const categoryObj = WORD_LISTS[props.match.params.id-1];
    //const name = WORD_LISTS[props.match.params.id-1].category
    const name = categoryObj.category
    //const img = `/images/${WORD_LISTS[props.match.params.id-1].imageUrl}`;
    const img = `/images/${categoryObj.imageUrl}`;
    //console.log("received "+this.match.location.state.id);
    //console.log(props.match.params);
    //console.log(props.location.state.id);
    //<h2>{name}</h2>
    //console.log(img);
    //<div className='pa2' id="customers"> 
    //console.log("wordpage");
    //console.log(props);
    return(
      <div className="mainbody">
        <img alt={name} src={img}/>
        <div>              
              <br />
              <WordList words={categoryObj.words} />
        </div>
      </div>
    );
}
//<WordList words={WORD_LISTS[props.location.state.id-1].words} />
//<ListItems words={WORD_LISTS[props.location.state.id].words}/>
export default WordPage;
/*{
function NumberList(props) {
  const numbers = props.numbers;
  const listItems = numbers.map((number) =>
    <li>{number}</li>
  );
  return (
    <ul>{listItems}</ul>
  );
}

const numbers = [1, 2, 3, 4, 5];
ReactDOM.render(
  <NumberList numbers={numbers} />,
  document.getElementById('root')
); }*/