import React from 'react';
import { Link } from 'react-router-dom';

const SideBar = () => (
    <nav>
        <ul>
            <li>
                <Link to="/">Lessons</Link>
                <Link to="/">Vocabulary</Link>
                <Link to="/">Grammar</Link>
                <Link to="/">Articles</Link>
                <Link to="/">Reading</Link>
                <Link to="/">Games</Link>
                <Link to="/">Profile</Link>
            </li>
        </ul>
    </nav>
);

//<Link to="/article-list">Login</Link>

export default SideBar;
