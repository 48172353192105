import React, {useState, useEffect} from 'react';
import CardList from '../components/cardList';
import SearchBox from '../components/searchBox';
//import Scroll from '../components/scroll';
//import './App.css';
import {WORD_LISTS} from '../words';


function CategoryPage() {

  const [robotData, setRobotData] = useState([]);
  const [robotArray, setRobotsArray] = useState(WORD_LISTS);
  
  useEffect(() => { 
    async function fetchData()
    {
      //const response = await fetch('https://jsonplaceholder.typicode.com/users');
      //const body = await response.json();
      //setRobotData(body);
      setRobotData(WORD_LISTS);
      //setRobotsArray(WORD_LISTS);
    }
    fetchData();
  },[]); //use [] so only called on mount

  const onSearchChange = (event) =>{
    setRobotsArray(robotData.filter(robot => {
      return robot.category.toLowerCase().includes(event.target.value.toLowerCase())
    }));

    //console.log(event.target.value.toLowerCase());
  };

  //if(robotData.length === 0)
  //{
  //  return <h1>Loading...</h1>
  //}
  //else
  //{
    //console.log(robotArray);
    return (
      <div className="mainbody">
        <SearchBox searchChange={onSearchChange}/>
        <CardList robots={robotArray} />
      </div>
    );
  //}
}
//
//<div className="App">
//<SearchBox searchChange={onSearchChange}/>
//<CardList robots={robotArray} />
//</div>

export default CategoryPage;
