import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NavBar from './components/navbar';
import SideBar from './components/sidebar';
import AboutPage from './pages/About';
import CategoryPage from './pages/CategoryPage';
import WordPage from './pages/WordPage';

function App() {
  return (
    <Router>
      <div id="content">
          <header><NavBar /></header>
          <main>
            <Switch>
                <Route path="/" component={CategoryPage} exact/>
                <Route path="/words/:id" component={WordPage}/>
                <Route path="/about/" component={AboutPage}/>
            </Switch>
          </main>
          {/*<section>Section</section>*/}
          <sidenav><SideBar /></sidenav>
          <footer></footer>
      </div>
    </Router>
  );
}

//<Route path="/" component={CategoryPage} exact/>
//<Route path="/words/:id" component={WordPage}/>

export default App;
