export const WORD_LISTS = [
{
	id: 1,
	category: 'Fruit 1',
	imageUrl: 'Fruit1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Apple',
		chinese_name: '苹果',
		pinyin: 'píng guǒ',
	},
	{
		id: 2,
		english_name: 'Pear',
		chinese_name: '梨',
		pinyin: 'lí',
	},
	{
		id: 3,
		english_name: 'Orange',
		chinese_name: '橘子',
		pinyin: 'jú zǐ',
	},
	{
		id: 4,
		english_name: 'Banana',
		chinese_name: '香蕉',
		pinyin: 'xiāng jiāo',
	},
	{
		id: 5,
		english_name: 'Strawberry',
		chinese_name: '草莓',
		pinyin: 'cǎo méi',
	},
	{
		id: 6,
		english_name: 'Grape',
		chinese_name: '葡萄',
		pinyin: 'pú táo',
	},
	{
		id: 7,
		english_name: 'Peach',
		chinese_name: '桃子',
		pinyin: 'táo zi',
	},
	{
		id: 8,
		english_name: 'Water Melon',
		chinese_name: '西瓜',
		pinyin: 'xī guā',
	},
	{
		id: 9,
		english_name: 'Lemon',
		chinese_name: '柠檬',
		pinyin: 'níng méng',
	},
	{
		id: 10,
		english_name: 'Kiwi',
		chinese_name: '奇异果',
		pinyin: 'qí yì guǒ',
	},
	]
},
	{
	id: 2,
	category: 'Animals 1',
	imageUrl: 'Animals1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Dog',
		chinese_name: '狗',
		pinyin: 'gǒu',
	},
	{
		id: 2,
		english_name: 'Cat',
		chinese_name: '猫',
		pinyin: 'māo',
	},
	{
		id: 3,
		english_name: 'Horse',
		chinese_name: '马',
		pinyin: 'mǎ',
	},
	{
		id: 4,
		english_name: 'Bird',
		chinese_name: '鸟',
		pinyin: 'niǎo',
	},
	{
		id: 5,
		english_name: 'Fish',
		chinese_name: '鱼',
		pinyin: 'yú',
	},
	{
		id: 6,
		english_name: 'Monkey',
		chinese_name: '猴子',
		pinyin: 'hóu zi',
	},
	{
		id: 7,
		english_name: 'Rabbit',
		chinese_name: '兔子',
		pinyin: 'tù zi',
	},
	{
		id: 8,
		english_name: 'Elephant',
		chinese_name: '大象',
		pinyin: 'dà xiàng',
	},
	{
		id: 9,
		english_name: 'Lion',
		chinese_name: '狮子',
		pinyin: 'shī zi',
	},
	{
		id: 10,
		english_name: 'Tiger',
		chinese_name: '老虎',
		pinyin: 'lǎo hǔ',
	},
	]
},
	{
	id: 3,
	category: 'Vegetables 1',
	imageUrl: 'Vegetables1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Beans',
		chinese_name: '豆',
		pinyin: 'dòu',
	},
	{
		id: 2,
		english_name: 'Carrot',
		chinese_name: '胡萝卜',
		pinyin: 'hú luó bo',
	},
	{
		id: 3,
		english_name: 'Corn',
		chinese_name: '玉米',
		pinyin: 'yù mǐ',
	},
	{
		id: 4,
		english_name: 'Lettuce',
		chinese_name: '生菜',
		pinyin: 'shēng cài',
	},
	{
		id: 5,
		english_name: 'Mushroom',
		chinese_name: '蘑菇',
		pinyin: 'mó gu',
	},
	{
		id: 6,
		english_name: 'Onion',
		chinese_name: '洋葱',
		pinyin: 'yáng cōng',
	},
	{
		id: 7,
		english_name: 'Pepper',
		chinese_name: '青椒',
		pinyin: 'qīng jiāo',
	},
	{
		id: 8,
		english_name: 'Potato',
		chinese_name: '土豆',
		pinyin: 'tǔ dòu',
	},
	{
		id: 9,
		english_name: 'Pumpking',
		chinese_name: '南瓜',
		pinyin: 'nán guā',
	},
	{
		id: 10,
		english_name: 'Spinach',
		chinese_name: '菠菜',
		pinyin: 'bō cài',
	},
	]
},
	{
	id: 4,
	category: 'Entertainment 1',
	imageUrl: 'Entertainment1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Amusement Park',
		chinese_name: '游乐场',
		pinyin: 'yóu lè chǎng',
	},
	{
		id: 2,
		english_name: 'Bar',
		chinese_name: '酒吧',
		pinyin: 'jiǔ bā',
	},
	{
		id: 3,
		english_name: 'Beach',
		chinese_name: '海滩',
		pinyin: 'hǎi tān',
	},
	{
		id: 4,
		english_name: 'Cinema',
		chinese_name: '电影院',
		pinyin: 'diàn yǐng yuàn',
	},
	{
		id: 5,
		english_name: 'Coffee Shop',
		chinese_name: '咖啡店',
		pinyin: 'kā fēi diàn',
	},
	{
		id: 6,
		english_name: 'Library',
		chinese_name: '图书馆',
		pinyin: 'tú shū guǎn',
	},
	{
		id: 7,
		english_name: 'Museum',
		chinese_name: '博物馆',
		pinyin: 'bó wù guǎn',
	},
	{
		id: 8,
		english_name: 'Park',
		chinese_name: '公园',
		pinyin: 'gōng yuán',
	},
	{
		id: 9,
		english_name: 'Restaurant',
		chinese_name: '餐厅',
		pinyin: 'cān tīng',
	},
	{
		id: 10,
		english_name: 'Zoo',
		chinese_name: '动物园',
		pinyin: 'dòng wù yuán',
	},
	]
},
	{
	id: 5,
	category: 'Home Objects 1',
	imageUrl: 'HomeObjects1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Television',
		chinese_name: '电视',
		pinyin: 'diàn shì',
	},
	{
		id: 2,
		english_name: 'Sofa',
		chinese_name: '沙发',
		pinyin: 'shā fā',
	},
	{
		id: 3,
		english_name: 'Dining Table',
		chinese_name: '餐桌',
		pinyin: 'cān zhuō',
	},
	{
		id: 4,
		english_name: 'Chair',
		chinese_name: '座椅',
		pinyin: 'zuò yǐ',
	},
	{
		id: 5,
		english_name: 'Curtain',
		chinese_name: '窗帘',
		pinyin: 'chuāng lián',
	},
	{
		id: 6,
		english_name: 'Desk Lamp',
		chinese_name: '台灯',
		pinyin: 'tái dēng',
	},
	{
		id: 7,
		english_name: 'chandelier',
		chinese_name: '吊灯',
		pinyin: 'diào dēng',
	},
	{
		id: 8,
		english_name: 'Bookcase',
		chinese_name: '书柜',
		pinyin: 'shū guì',
	},
	{
		id: 9,
		english_name: 'Warderobe',
		chinese_name: '衣柜',
		pinyin: 'yī guì',
	},
	{
		id: 10,
		english_name: 'Bed',
		chinese_name: '睡床',
		pinyin: 'shuì chuáng',
	},
	]
},
	{
	id: 6,
	category: 'Professions 1',
	imageUrl: 'Professions1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Doctor',
		chinese_name: '医生',
		pinyin: 'yī shēng',
	},
	{
		id: 2,
		english_name: 'Nurse',
		chinese_name: '护士',
		pinyin: 'hù shi',
	},
	{
		id: 3,
		english_name: 'Journalist',
		chinese_name: '记者',
		pinyin: 'jì zhě',
	},
	{
		id: 4,
		english_name: 'Teacher',
		chinese_name: '老师',
		pinyin: 'lǎo shī',
	},
	{
		id: 5,
		english_name: 'Police',
		chinese_name: '警察',
		pinyin: 'jǐng chá',
	},
	{
		id: 6,
		english_name: 'Driver',
		chinese_name: '司机',
		pinyin: 'sī jī',
	},
	{
		id: 7,
		english_name: 'Cook',
		chinese_name: '厨师',
		pinyin: 'chú shī',
	},
	{
		id: 8,
		english_name: 'Lawyer',
		chinese_name: '律师',
		pinyin: 'lǜ shī',
	},
	{
		id: 9,
		english_name: 'Accountant',
		chinese_name: '会计',
		pinyin: 'kuài jì',
	},
	{
		id: 10,
		english_name: 'Firefighter',
		chinese_name: '消防员',
		pinyin: 'xiāo fáng yuán',
	},
	]
},
	{
	id: 7,
	category: 'Countries 1',
	imageUrl: 'Countries1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'China',
		chinese_name: '中国',
		pinyin: 'zhōng guó',
	},
	{
		id: 2,
		english_name: 'Britain',
		chinese_name: '英国',
		pinyin: 'yīng guó',
	},
	{
		id: 3,
		english_name: 'America',
		chinese_name: '美国',
		pinyin: 'měi guó',
	},
	{
		id: 4,
		english_name: 'France',
		chinese_name: '法国',
		pinyin: 'fǎ guó',
	},
	{
		id: 5,
		english_name: 'Germany',
		chinese_name: '德国',
		pinyin: 'dé guó',
	},
	{
		id: 6,
		english_name: 'Japan',
		chinese_name: '日本',
		pinyin: 'rì běn',
	},
	{
		id: 7,
		english_name: 'India',
		chinese_name: '印度',
		pinyin: 'yìn dù',
	},
	{
		id: 8,
		english_name: 'Canada',
		chinese_name: '加拿大',
		pinyin: 'jiā ná dà',
	},
	{
		id: 9,
		english_name: 'Russia',
		chinese_name: '俄罗斯',
		pinyin: 'é luó sī',
	},
	{
		id: 10,
		english_name: 'Italy',
		chinese_name: '意大利',
		pinyin: 'yì dà lì',
	},
	]
},
	{
	id: 8,
	category: 'Cities 1',
	imageUrl: 'Cities1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Beijing',
		chinese_name: '北京',
		pinyin: 'běi jīng',
	},
	{
		id: 2,
		english_name: 'Berlin',
		chinese_name: '柏林',
		pinyin: 'bó lín',
	},
	{
		id: 3,
		english_name: 'London',
		chinese_name: '伦敦',
		pinyin: 'lún dūn',
	},
	{
		id: 4,
		english_name: 'Madrid',
		chinese_name: '马德里',
		pinyin: 'mǎ dé lǐ',
	},
	{
		id: 5,
		english_name: 'Moscow',
		chinese_name: '莫斯科',
		pinyin: 'mò sī kē',
	},
	{
		id: 6,
		english_name: 'New York',
		chinese_name: '纽约',
		pinyin: 'niǔ yuē',
	},
	{
		id: 7,
		english_name: 'Paris',
		chinese_name: '巴黎',
		pinyin: 'bā lí',
	},
	{
		id: 8,
		english_name: 'Rome',
		chinese_name: '罗马',
		pinyin: 'luó mǎ',
	},
	{
		id: 9,
		english_name: 'Shanghai',
		chinese_name: '上海',
		pinyin: 'shàng hǎi',
	},
	{
		id: 10,
		english_name: 'Toronto',
		chinese_name: '多伦多',
		pinyin: 'duō lún duō',
	},
	]
},
	{
	id: 9,
	category: 'Rooms 1',
	imageUrl: 'Rooms1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Living Room',
		chinese_name: '客厅',
		pinyin: 'kè tīng',
	},
	{
		id: 2,
		english_name: 'Dining Room',
		chinese_name: '饭厅',
		pinyin: 'fàn tīng',
	},
	{
		id: 3,
		english_name: 'Kitchen',
		chinese_name: '厨房',
		pinyin: 'chú fáng',
	},
	{
		id: 4,
		english_name: 'Bath Room',
		chinese_name: '浴室',
		pinyin: 'yù shì',
	},
	{
		id: 5,
		english_name: 'Shower Room',
		chinese_name: '淋浴间',
		pinyin: 'lín yù jiān',
	},
	{
		id: 6,
		english_name: 'Toilet',
		chinese_name: '厕所',
		pinyin: 'cè suǒ',
	},
	{
		id: 7,
		english_name: 'Corridor',
		chinese_name: '走廊',
		pinyin: 'zǒu láng',
	},
	{
		id: 8,
		english_name: 'Loft',
		chinese_name: '阁楼',
		pinyin: 'gé lóu',
	},
	{
		id: 9,
		english_name: 'Bedroom',
		chinese_name: '睡房',
		pinyin: 'shuì fáng',
	},
	{
		id: 10,
		english_name: 'Master Suite',
		chinese_name: '主人套房',
		pinyin: 'zhǔ rén tào fáng',
	},
	]
},
	{
	id: 10,
	category: 'Body 1',
	imageUrl: 'Body1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Head',
		chinese_name: '头',
		pinyin: 'tóu',
	},
	{
		id: 2,
		english_name: 'Face',
		chinese_name: '脸',
		pinyin: 'liǎn',
	},
	{
		id: 3,
		english_name: 'Eye',
		chinese_name: '眼睛',
		pinyin: 'yǎn jing',
	},
	{
		id: 4,
		english_name: 'Nose',
		chinese_name: '鼻子',
		pinyin: 'bí zi',
	},
	{
		id: 5,
		english_name: 'Mouth',
		chinese_name: '嘴巴',
		pinyin: 'zuǐ ba',
	},
	{
		id: 6,
		english_name: 'Teeth',
		chinese_name: '牙齿',
		pinyin: 'yá chǐ',
	},
	{
		id: 7,
		english_name: 'Tongue',
		chinese_name: '舌头',
		pinyin: 'shé tou',
	},
	{
		id: 8,
		english_name: 'Ear',
		chinese_name: '耳朵',
		pinyin: 'ěr duo',
	},
	{
		id: 9,
		english_name: 'Hair',
		chinese_name: '头发',
		pinyin: 'tóu fa',
	},
	{
		id: 10,
		english_name: 'Neck',
		chinese_name: '脖子',
		pinyin: 'bó zi',
	},
	]
},
	{
	id: 11,
	category: 'Direction',
	imageUrl: 'Directionsm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Up',
		chinese_name: '上',
		pinyin: 'shàng',
	},
	{
		id: 2,
		english_name: 'Down',
		chinese_name: '下',
		pinyin: 'xià',
	},
	{
		id: 3,
		english_name: 'East',
		chinese_name: '东',
		pinyin: 'dōng',
	},
	{
		id: 4,
		english_name: 'South',
		chinese_name: '南',
		pinyin: 'nán',
	},
	{
		id: 5,
		english_name: 'West',
		chinese_name: '西',
		pinyin: 'xī',
	},
	{
		id: 6,
		english_name: 'North',
		chinese_name: '北',
		pinyin: 'běi',
	},
	{
		id: 7,
		english_name: 'Middle',
		chinese_name: '中',
		pinyin: 'zhōng',
	},
	{
		id: 8,
		english_name: 'Inside',
		chinese_name: '里',
		pinyin: 'lǐ',
	},
	{
		id: 9,
		english_name: 'Outside',
		chinese_name: '外',
		pinyin: 'wài',
	},
	{
		id: 10,
		english_name: 'Beside',
		chinese_name: '旁',
		pinyin: 'páng',
	},
	]
},
	{
	id: 12,
	category: 'Kitchen Appliance 1',
	imageUrl: 'KitchenAppliance1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Pan',
		chinese_name: '锅',
		pinyin: 'guō',
	},
	{
		id: 2,
		english_name: 'Frying Pan',
		chinese_name: '煎锅',
		pinyin: 'jiān guō',
	},
	{
		id: 3,
		english_name: 'Wok',
		chinese_name: '炒锅',
		pinyin: 'chǎo guō',
	},
	{
		id: 4,
		english_name: 'Pressure Cooker',
		chinese_name: '高压锅',
		pinyin: 'gāo yā guō',
	},
	{
		id: 5,
		english_name: 'Rice Cooker',
		chinese_name: '电饭锅',
		pinyin: 'diàn fàn guō',
	},
	{
		id: 6,
		english_name: 'Oven',
		chinese_name: '烤炉',
		pinyin: 'kǎo lú',
	},
	{
		id: 7,
		english_name: 'Microwave',
		chinese_name: '微波炉',
		pinyin: 'wēi bō lú',
	},
	{
		id: 8,
		english_name: 'Fridge',
		chinese_name: '冰箱',
		pinyin: 'bīng xiāng',
	},
	{
		id: 9,
		english_name: 'Dishwasher',
		chinese_name: '洗碗机',
		pinyin: 'xǐ wǎn jī',
	},
	{
		id: 10,
		english_name: 'Kettle',
		chinese_name: '烧水壶',
		pinyin: 'shāo shuǐ hú',
	},
	]
},
	{
	id: 13,
	category: 'Kitchen Appliance 2',
	imageUrl: 'KitchenAppliance2sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Washing Machine',
		chinese_name: '洗衣机',
		pinyin: 'xǐ yī jī',
	},
	{
		id: 2,
		english_name: 'Coffee Machine',
		chinese_name: '咖啡机',
		pinyin: 'kā fēi jī',
	},
	{
		id: 3,
		english_name: 'Toaster',
		chinese_name: '吐司机',
		pinyin: 'tǔ sī jī',
	},
	{
		id: 4,
		english_name: 'Tin Opener',
		chinese_name: '开罐器',
		pinyin: 'kāi guàn qì',
	},
	{
		id: 5,
		english_name: 'Stove',
		chinese_name: '炉子',
		pinyin: 'lú zi',
	},
	{
		id: 6,
		english_name: 'Gas Stove',
		chinese_name: '煤气炉',
		pinyin: 'méi qì lú',
	},
	{
		id: 7,
		english_name: 'Grill',
		chinese_name: '烧烤炉',
		pinyin: 'shāo kǎo lú',
	},
	{
		id: 8,
		english_name: 'Knife',
		chinese_name: '刀',
		pinyin: 'dāo',
	},
	{
		id: 9,
		english_name: 'Fork',
		chinese_name: '叉',
		pinyin: 'chā',
	},
	{
		id: 10,
		english_name: 'Cutting Board',
		chinese_name: '菜板',
		pinyin: 'cài bǎn',
	},
	]
},
	{
	id: 14,
	category: 'Clothes 1',
	imageUrl: 'Clothes1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Shirt',
		chinese_name: '衬衫',
		pinyin: 'chèn shān',
	},
	{
		id: 2,
		english_name: 'Cardigan',
		chinese_name: '毛衣',
		pinyin: 'máo yī',
	},
	{
		id: 3,
		english_name: 'Coat',
		chinese_name: '大衣',
		pinyin: 'dà yī',
	},
	{
		id: 4,
		english_name: 'Jacket',
		chinese_name: '夹克',
		pinyin: 'jiā kè',
	},
	{
		id: 5,
		english_name: 'T Shirt',
		chinese_name: 'T恤',
		pinyin: 'T xù',
	},
	{
		id: 6,
		english_name: 'Sweater',
		chinese_name: '卫衣',
		pinyin: 'wèi yī',
	},
	{
		id: 7,
		english_name: 'Vest',
		chinese_name: '背心',
		pinyin: 'bèi xīn',
	},
	{
		id: 8,
		english_name: 'Waistcoat',
		chinese_name: '马夹',
		pinyin: 'mǎ jiá',
	},
	{
		id: 9,
		english_name: 'Long Sleeves',
		chinese_name: '长袖',
		pinyin: 'cháng xiù',
	},
	{
		id: 10,
		english_name: 'Short Sleeves',
		chinese_name: '短袖',
		pinyin: 'duǎn xiù',
	},
	]
},
	{
	id: 15,
	category: 'Clothes 2',
	imageUrl: 'Clothes2sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Trousers',
		chinese_name: '长裤',
		pinyin: 'cháng kù',
	},
	{
		id: 2,
		english_name: 'Shorts',
		chinese_name: '短裤',
		pinyin: 'duǎn kù',
	},
	{
		id: 3,
		english_name: 'Cropped Pants',
		chinese_name: '七分裤',
		pinyin: 'qī fēn kù',
	},
	{
		id: 4,
		english_name: 'Jeans',
		chinese_name: '牛仔裤',
		pinyin: 'niú zǎi kù',
	},
	{
		id: 5,
		english_name: 'Suit Pants',
		chinese_name: '西装裤',
		pinyin: 'xī zhuāng kù',
	},
	{
		id: 6,
		english_name: 'High Waisted',
		chinese_name: '高腰裤',
		pinyin: 'gāo yāo kù',
	},
	{
		id: 7,
		english_name: 'Low Waisted',
		chinese_name: '低腰裤',
		pinyin: 'dī yāo kù',
	},
	{
		id: 8,
		english_name: 'Pencil Pants',
		chinese_name: '窄腿裤',
		pinyin: 'zhǎi tuǐ kù',
	},
	{
		id: 9,
		english_name: 'Straight Leg pants',
		chinese_name: '直筒裤',
		pinyin: 'zhí tǒng kù',
	},
	{
		id: 10,
		english_name: 'Flared Trousers',
		chinese_name: '喇叭裤',
		pinyin: 'lǎ bā kù',
	},
	]
},
	{
	id: 16,
	category: 'Numbers 1',
	imageUrl: 'Numbers1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'One',
		chinese_name: '一',
		pinyin: 'yī',
	},
	{
		id: 2,
		english_name: 'Two',
		chinese_name: '二',
		pinyin: 'èr',
	},
	{
		id: 3,
		english_name: 'Three',
		chinese_name: '三',
		pinyin: 'sān',
	},
	{
		id: 4,
		english_name: 'Four',
		chinese_name: '四',
		pinyin: 'sì',
	},
	{
		id: 5,
		english_name: 'Five',
		chinese_name: '五',
		pinyin: 'wǔ',
	},
	{
		id: 6,
		english_name: 'Six',
		chinese_name: '六',
		pinyin: 'liù',
	},
	{
		id: 7,
		english_name: 'Seven',
		chinese_name: '七',
		pinyin: 'qī',
	},
	{
		id: 8,
		english_name: 'Eight',
		chinese_name: '八',
		pinyin: 'bā',
	},
	{
		id: 9,
		english_name: 'Nine',
		chinese_name: '九',
		pinyin: 'jiǔ',
	},
	{
		id: 10,
		english_name: 'Ten',
		chinese_name: '十',
		pinyin: 'shí',
	},
	]
},
	{
	id: 17,
	category: 'Musical Instruments  1',
	imageUrl: 'MusicalInstruments1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Piano',
		chinese_name: '钢琴',
		pinyin: 'gāng qín',
	},
	{
		id: 2,
		english_name: 'Violin',
		chinese_name: '小提琴',
		pinyin: 'xiǎo tí qín',
	},
	{
		id: 3,
		english_name: 'Viola',
		chinese_name: '中提琴',
		pinyin: 'zhōng tí qín',
	},
	{
		id: 4,
		english_name: 'Cello',
		chinese_name: '大提琴',
		pinyin: 'dà tí qín',
	},
	{
		id: 5,
		english_name: 'Guitar',
		chinese_name: '竖琴',
		pinyin: 'shù qín',
	},
	{
		id: 6,
		english_name: 'Guitar',
		chinese_name: '吉他',
		pinyin: 'jí tā',
	},
	{
		id: 7,
		english_name: 'Saxophone',
		chinese_name: '萨克斯管',
		pinyin: 'sà kè sī guǎn',
	},
	{
		id: 8,
		english_name: 'Trumpet',
		chinese_name: '小号',
		pinyin: 'xiǎo hào',
	},
	{
		id: 9,
		english_name: 'Cymbal',
		chinese_name: '钹',
		pinyin: 'bó',
	},
	{
		id: 10,
		english_name: 'Gong',
		chinese_name: '锣',
		pinyin: 'luó',
	},
	]
},
	{
	id: 18,
	category: 'Weather 1',
	imageUrl: 'Weather1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Spring',
		chinese_name: '春天',
		pinyin: 'chūn tiān',
	},
	{
		id: 2,
		english_name: 'Summer',
		chinese_name: '夏天',
		pinyin: 'xià tiān',
	},
	{
		id: 3,
		english_name: 'Autumn',
		chinese_name: '秋天',
		pinyin: 'qiū tiān',
	},
	{
		id: 4,
		english_name: 'Winter',
		chinese_name: '冬天',
		pinyin: 'dōng tiān',
	},
	{
		id: 5,
		english_name: 'Sunny',
		chinese_name: '晴天',
		pinyin: 'qíng tiān',
	},
	{
		id: 6,
		english_name: 'Cloudy',
		chinese_name: '多云',
		pinyin: 'duō yún',
	},
	{
		id: 7,
		english_name: 'Hail',
		chinese_name: '冰雹',
		pinyin: 'bīng báo',
	},
	{
		id: 8,
		english_name: 'Fog',
		chinese_name: '雾',
		pinyin: 'wù',
	},
	{
		id: 9,
		english_name: 'Rain',
		chinese_name: '雨',
		pinyin: 'yǔ',
	},
	{
		id: 10,
		english_name: 'Snow',
		chinese_name: '雪',
		pinyin: 'xuě',
	},
	]
},
	{
	id: 19,
	category: 'Insects 1',
	imageUrl: 'Insects1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Ladybird',
		chinese_name: '七星瓢虫',
		pinyin: 'qī xīng piáo chóng',
	},
	{
		id: 2,
		english_name: 'Dragonfly',
		chinese_name: '蜻蜓',
		pinyin: 'qīng tíng',
	},
	{
		id: 3,
		english_name: 'Butterfly',
		chinese_name: '蝴蝶',
		pinyin: 'hú dié',
	},
	{
		id: 4,
		english_name: 'Mantis',
		chinese_name: '螳螂',
		pinyin: 'táng láng',
	},
	{
		id: 5,
		english_name: 'Bee',
		chinese_name: '蜜蜂',
		pinyin: 'mì fēng',
	},
	{
		id: 6,
		english_name: 'Locust',
		chinese_name: '蝗虫',
		pinyin: 'huáng chóng',
	},
	{
		id: 7,
		english_name: 'Ant',
		chinese_name: '蚂蚁',
		pinyin: 'mǎ yǐ',
	},
	{
		id: 8,
		english_name: 'Mosquito',
		chinese_name: '蚊子',
		pinyin: 'wén zi',
	},
	{
		id: 9,
		english_name: 'Fly',
		chinese_name: '苍蝇',
		pinyin: 'cāng ying',
	},
	{
		id: 10,
		english_name: 'Spider',
		chinese_name: '蜘蛛',
		pinyin: 'zhī zhū',
	},
	]
},
	{
	id: 20,
	category: 'Family 1',
	imageUrl: 'Family1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Mother',
		chinese_name: '妈妈',
		pinyin: 'mā ma',
	},
	{
		id: 2,
		english_name: 'Father',
		chinese_name: '爸爸',
		pinyin: 'bà ba',
	},
	{
		id: 3,
		english_name: 'Daughter',
		chinese_name: '女儿',
		pinyin: 'nǚ ér',
	},
	{
		id: 4,
		english_name: 'Son',
		chinese_name: '儿子',
		pinyin: 'ér zi',
	},
	{
		id: 5,
		english_name: 'Older Brother',
		chinese_name: '哥哥',
		pinyin: 'gē ge',
	},
	{
		id: 6,
		english_name: 'Older Sister',
		chinese_name: '姐姐',
		pinyin: 'jiě jie',
	},
	{
		id: 7,
		english_name: 'Little Brother',
		chinese_name: '弟弟',
		pinyin: 'dì di',
	},
	{
		id: 8,
		english_name: 'Little Sister',
		chinese_name: '妹妹',
		pinyin: 'mèi mèi',
	},
	{
		id: 9,
		english_name: 'Grandfather',
		chinese_name: '爷爷',
		pinyin: 'yé ye',
	},
	{
		id: 10,
		english_name: 'Grandmother',
		chinese_name: '奶奶',
		pinyin: 'nǎi nai ',
	},
	]
},
	{
	id: 21,
	category: 'Ball Games 1',
	imageUrl: 'BallGames1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Football',
		chinese_name: '足球',
		pinyin: 'zú qiú',
	},
	{
		id: 2,
		english_name: 'Basketball',
		chinese_name: '篮球',
		pinyin: 'lán qiú ',
	},
	{
		id: 3,
		english_name: 'Paddle Ball',
		chinese_name: '板球',
		pinyin: 'bǎn qiú',
	},
	{
		id: 4,
		english_name: 'Volleyball',
		chinese_name: '排球',
		pinyin: 'pái qiú',
	},
	{
		id: 5,
		english_name: 'Tennis',
		chinese_name: '网球',
		pinyin: 'wǎng qiú',
	},
	{
		id: 6,
		english_name: 'Baseball',
		chinese_name: '棒球',
		pinyin: 'bàng qiú',
	},
	{
		id: 7,
		english_name: 'Rugby',
		chinese_name: '橄榄球',
		pinyin: 'gǎn lǎn qiú',
	},
	{
		id: 8,
		english_name: 'Table Tennis',
		chinese_name: '乒乓球',
		pinyin: 'pīng pāng qiú',
	},
	{
		id: 9,
		english_name: 'Badminton',
		chinese_name: '羽毛球',
		pinyin: 'yǔ máo qiú',
	},
	{
		id: 10,
		english_name: 'Golf',
		chinese_name: '高尔夫球',
		pinyin: 'gāo ěr fū qiú',
	},
	]
},
	{
	id: 22,
	category: 'Olympic Games 1',
	imageUrl: 'OlympicGames1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Athletics',
		chinese_name: '田径',
		pinyin: 'tián jìng',
	},
	{
		id: 2,
		english_name: 'Rowing',
		chinese_name: '赛艇',
		pinyin: 'sài tǐng',
	},
	{
		id: 3,
		english_name: 'Swimming',
		chinese_name: '游泳',
		pinyin: 'yóu yǒng',
	},
	{
		id: 4,
		english_name: 'Boxing',
		chinese_name: '拳击',
		pinyin: 'quán jī',
	},
	{
		id: 5,
		english_name: 'Equestrian',
		chinese_name: '马术',
		pinyin: 'mǎ shù',
	},
	{
		id: 6,
		english_name: 'Weightlifting',
		chinese_name: '举重',
		pinyin: 'jǔ zhòng',
	},
	{
		id: 7,
		english_name: 'Fencing',
		chinese_name: '击剑',
		pinyin: 'jī jiàn',
	},
	{
		id: 8,
		english_name: 'Shooting',
		chinese_name: '射击',
		pinyin: 'shè jī',
	},
	{
		id: 9,
		english_name: 'Gymnastics',
		chinese_name: '体操',
		pinyin: 'tǐ cāo',
	},
	{
		id: 10,
		english_name: 'Wrestling',
		chinese_name: '摔跤',
		pinyin: 'shuāi jiāo',
	},
	]
},
	{
	id: 23,
	category: 'Skin Care 1',
	imageUrl: 'SkinCare1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Face Wash',
		chinese_name: '洗面奶',
		pinyin: 'xǐ miàn nǎi',
	},
	{
		id: 2,
		english_name: 'Toner',
		chinese_name: '爽肤水',
		pinyin: 'shuǎng fū shuǐ',
	},
	{
		id: 3,
		english_name: 'Firming Lotion',
		chinese_name: '紧肤水',
		pinyin: 'jǐn fū shuǐ',
	},
	{
		id: 4,
		english_name: 'Face Cream',
		chinese_name: '护肤霜',
		pinyin: 'hù fū shuāng',
	},
	{
		id: 5,
		english_name: 'Moisturizer',
		chinese_name: '保湿霜',
		pinyin: 'bǎo shī shuāng',
	},
	{
		id: 6,
		english_name: 'Whitening',
		chinese_name: '美白霜',
		pinyin: 'měi bái shuāng',
	},
	{
		id: 7,
		english_name: 'Day Cream',
		chinese_name: '日霜',
		pinyin: 'rì shuāng',
	},
	{
		id: 8,
		english_name: 'Night Cream',
		chinese_name: '晚霜',
		pinyin: 'wǎn shuāng',
	},
	{
		id: 9,
		english_name: 'Eye Gel',
		chinese_name: '眼胶',
		pinyin: 'yǎn jiāo',
	},
	{
		id: 10,
		english_name: 'Facial Mask',
		chinese_name: '面膜',
		pinyin: 'miàn mó',
	},
	]
},
	{
	id: 24,
	category: 'Cleaning Products 1',
	imageUrl: 'CleaningProducts1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Mop',
		chinese_name: '拖把',
		pinyin: 'tuō bǎ',
	},
	{
		id: 2,
		english_name: 'Broom',
		chinese_name: '扫把',
		pinyin: 'sào bɑ',
	},
	{
		id: 3,
		english_name: 'Cloth',
		chinese_name: '抹布',
		pinyin: 'mā bù',
	},
	{
		id: 4,
		english_name: 'Gloves',
		chinese_name: '手套',
		pinyin: 'shǒu tào',
	},
	{
		id: 5,
		english_name: 'Scourer Pad',
		chinese_name: '百洁布',
		pinyin: 'bǎi jié bù',
	},
	{
		id: 6,
		english_name: 'Toilet Brush',
		chinese_name: '马桶刷',
		pinyin: 'mǎ tǒng shuā',
	},
	{
		id: 7,
		english_name: 'Window Cleaner',
		chinese_name: '擦窗器',
		pinyin: 'cā chuāng qì',
	},
	{
		id: 8,
		english_name: 'Washing Up Liquid',
		chinese_name: '洗洁精',
		pinyin: 'xǐ jié jīng',
	},
	{
		id: 9,
		english_name: 'Laundry Detergent',
		chinese_name: '洗衣液',
		pinyin: 'xĭ yī yè',
	},
	{
		id: 10,
		english_name: 'Disinfectant',
		chinese_name: '消毒液',
		pinyin: 'xiāo dú yè',
	},
	]
},
	{
	id: 25,
	category: 'Face 1',
	imageUrl: 'Face1sm.jpg',
	words: [ 
	{
		id: 1,
		english_name: 'Skin',
		chinese_name: '皮肤',
		pinyin: 'pí fū',
	},
	{
		id: 2,
		english_name: 'Eyebow',
		chinese_name: '眉毛',
		pinyin: 'méi máo',
	},
	{
		id: 3,
		english_name: 'Eyelash',
		chinese_name: '睫毛',
		pinyin: 'jié máo',
	},
	{
		id: 4,
		english_name: 'Eye',
		chinese_name: '眼睛',
		pinyin: 'yǎn jīng',
	},
	{
		id: 5,
		english_name: 'Nose',
		chinese_name: '鼻子',
		pinyin: 'bí zi',
	},
	{
		id: 6,
		english_name: 'Nostril',
		chinese_name: '鼻孔',
		pinyin: 'bí kǒng',
	},
	{
		id: 7,
		english_name: 'Chin',
		chinese_name: '下巴',
		pinyin: 'xià bɑ',
	},
	{
		id: 8,
		english_name: 'Forehead',
		chinese_name: '前额',
		pinyin: 'qián é',
	},
	{
		id: 9,
		english_name: 'Lip',
		chinese_name: '嘴唇',
		pinyin: 'zuǐ chún',
	},
	{
		id: 10,
		english_name: 'Cheek',
		chinese_name: '脸颊',
		pinyin: 'liǎn jiá',
	},
	]
},
	
];